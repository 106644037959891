.mainindex{
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    padding: 50px;
    align-items: center;
    min-height: 90vh;
    height: fit-content;
    width: 60%;
}

.headingdiv{
    width: 100%;
    height: 200px;
    background-image: url(https://yourgutmap.co.uk/wp-content/uploads/2023/12/a.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6E4E9F;
}

.mainindexh1{
margin-top: 30px;
color: #6E4E9F;
width: 100%;
}

.catogary{
    width: 100%;
    display: flex;
    margin-top: 50px;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
}


.catogary h3{
    background-color: #BCC2FE;
    padding: 10px 20px;
    border-radius: 30px;
    color: #6E4E9F;
    cursor: pointer;
    transition: 1 ease;
}

.catogary h3:hover{
    background-color: #6E4E9F;
    color: #fff;
}

.formainpage{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
}

.formainpage button{
    background-color: #6E4E9F;
    border: 1px solid #6E4E9F;
    color: #fff;
    padding: 10px  20px;
    border-radius: 10px;
    cursor: pointer;
}

.formainpage button:hover{
   scale: 1.05;
}

.check{
    position: relative;
}

.check::after{
    content: "";
    width: 100%;
    height: 2px;
    background-color: #6E4E9F;
    position: absolute;
    left: 0;
    bottom: -50px;
}


@media screen and (max-width:1060px) {


    .mainindex {
        /* background-color: aqua; */
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        padding: 50px;
        align-items: center;
        min-height: 90vh;
        height: fit-content;
        width: 93%;
    }


    .headingdiv {
        width: 90%;
        height: 200px;
        background-image: url(https://yourgutmap.co.uk/wp-content/uploads/2023/12/a.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #6E4E9F;
        padding: 19px;
    }


    .check {
        position: relative;
        flex-direction: column;
    }
}
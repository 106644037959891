.main{
  font-family: Poppins;
}


*{
  padding: 0;
  margin: 0;
}



.maindiv {
    width: 100%;
    min-height: 90vh;
    height: fit-content;
    padding: 50px 0;
    background: rgb(188, 194, 254);
    background: linear-gradient(90deg, rgba(188, 194, 254, 1) 26%, rgba(225, 213, 255, 1) 50%, rgba(210, 205, 255, 1) 73%);
    display: flex;
    justify-content: center;
    align-items: center;

}

.selected{
    background-color: #6E4E9F !important;
    color: #fff !important;
}

.preview {
    width: 50%;
    border-radius: 20px;
    height: fit-content;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 10px rgb(170, 176, 238);

}

:where(.css-dev-only-do-not-override-161f05s).ant-select .ant-select-selection-wrap {
    display: flex;
    width: 100%;
    position:unset;
    min-width: 0;
}

.preview22 {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.meetingdetails {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* background-color: yellow; */

}

.meetingjoin {
    /* background-color: violet; */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.date-card {
    width: 100px !important;
    height: fit-content !important;
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    font-family: Arial, sans-serif;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.date-card .month {
    color: #E63946;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.date-card .day {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.date-card .time {
    font-size: 14px;
    color: #555;
    margin-top: 5px;
}

.head {
    display: flex;
    gap: 10px;

}

.head ul {
    margin-left: 10px !important;
}


.countdown-container {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    /* background-color:#6E4E9F; */
}

.countdown-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.highlight {
    color: #6E4E9F;
    /* Red highlight */
}

.countdown {
    /* background-color: #6E4E9F; */
    display: flex;
    justify-content: space-around;
    /* gap: 15px; */
}

.countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countdown-number {
    font-size: 2em;
    font-weight: bold;
    color: #6E4E9F;
    /* Red numbers */
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    width: 100%;
    text-align: center;
}

.countdown-label {
    font-size: 50%;
    margin-top: 10px;
    color: #333;
    font-weight: normal;
}

.registerform {
    /* background-color: #6E4E9F; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
}

.registerform div {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.registerform div input {
    /* background-color: #E63946; */
    box-shadow: 0 0 5px 1px #6E4E9F;
    border-radius: 5px;
    width: 96%;
    padding: 0 2%;
    height: 40px;
    border: 0;


}

.registerform div select {
    /* background-color: #E63946; */
    box-shadow: 0 0 5px 1px #6E4E9F;
    border-radius: 5px;
    width: 100%;
    padding: 0 2%;
    height: 40px;
    border: 0;


}


.registerform div button {
    /* background-color: #E63946; */
    box-shadow: 0 0 5px 1px #6E4E9F;
    border-radius: 5px;
    width: 100%;
    padding: 0 2%;
    height: 40px;
    border: 0;
    cursor: pointer;
    background-color: #6E4E9F;
    color: #fff;

}

.registerform div button:hover {
    background-color: #fff;
    border: 1px solid #6E4E9F;
    color: #6E4E9F;
}



.registerformdivbutton {
    /* background-color: #E63946; */
    box-shadow: 0 0 5px 1px #6E4E9F;
    border-radius: 5px;
    width: 100%;
    padding: 0 2%;
    height: 40px;
    border: 0;
    cursor: pointer;
    background-color: #6E4E9F;
    color: #fff;

}

.registerformdivbutton:hover {
    background-color: #fff;
    border: 1px solid #6E4E9F;
    color: #6E4E9F;
}



.alerttt {
    /* background-color: #000000; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
}

.alertbg {
    background-color: #6f4e9f85;
    width: 100%;
    height: 100vh;
    z-index: 2;

}

.alertcontant {
    background-color: #fff;
    z-index: 10;
    width: 40%;
    min-height: 200px;
    height: fit-content;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 2em;
    box-shadow: 0 0 10px 1px #6E4E9F;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    text-align: center;
}

.screen {
    /* width: 100%; */
    height: 100vh;
    /* display: flex;
    flex-direction: row; */
}

.vadio {
    /* background-color: #6E4E9F; */
    width: 80%;
    height: 90vh;
}

.vRymZYPLKPwd4n9uqA9E {
    width: 42px;
    height: 42px;
    background-color: #2c2f3e;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAAXNSR0IArs4c6QAAAKhQTFRFqqqqv7+/zMzM1dXVzObm29vb4ODg3Nzc2uHh29vi3d3j3Nzi4ODg3t7i39/i3eDg4OPj4OPj4eHk4eHk4eHk3+Ll4OLi4eHj4eHj4eHk4eHj4eHk4OLk4eHj4eHj4eHj3d/h4ODi3uDi3+Lk39/h4OHj4OHj4OHi3+Hj4OHj3+Hj4OHk4ODj4ODj3+Lj3+Dj4OHj4ODi3+Di3+Hi4ODj4OHj4OHj3+Dik9M1uAAAADd0Uk5TAwQFBgoVGR0iIyUsOUZHUlpbXV5fYWJlZmdvcHJ+f4CBgoOGj6zFy9LT2t/g4eLq9vf4+fn9/ohFHkAAAADkSURBVEjH7dbJDoIwGATg4q6guAtuVMV9R3He/83k4kGBFCcao3GOJF9oYNr+Qvzzcyna8j6OVUziWh5COTYTvC/CBbKghDaweFiqXAIdJZSAfHw2Bga/Di2gTcF0v5ei4C15K8fBOfYlEmKVZaBxAGYZAgrTB6YaAUU3KLzLQOEGsstAbQr4phI2xqFMdsDZUMA6YrJRwFocXKuWWh2EMtoCJ+NtH+d1v4MtAFs5uuT0tqI38vNHh64L6rCqXC5lCjrAkIKKNn0KRl2sC8Amr3IvwfgQNTx4LWpckXaiceWf780Vs5VXIaCww90AAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 28px 28px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
}


.xM8CBkrn0wtFOdOP84Bb textarea {
    width: 256px;
    height: 44px;
    background: rgba(48, 51, 65, 0.7);
    border-radius: 8px;
    padding: 12px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    line-height: 20px;
    margin-right: 8px;
    border: none;
    outline: none;
}



.aftermeeting {
    display: flex;
    background-color: #1C1F2E;
    height: 100vh;
    width: 100%;

}



.quetionnnwala {
    display: flex;

    overflow: auto;
    max-height: 90vh;
    max-width: 320px;
}

.videoplayer {
    width: 100% !important;
    height: 100% !important;
}

.loginmain{
    /* background-color: #6E4E9F; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

}

.loginform{
    background-color: #eeeded;
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 20%;
    border-radius: 20px;
}

.loginform h4{
    color: #6E4E9F;
}

.logindiv{
    /* background-color: #6E4E9F; */
    width: 100%;
    display: flex;
    flex-direction: column;
}

.logindiv input{
    /* height: 30px; */
    border-radius: 10px;
    border:1px solid #6E4E9F;
    padding: 15px;
    /* width: 100%; */
}

@media screen and (max-width:1060px) {

    .aftermeeting {
        display: flex;
        background-color: #1C1F2E;
        height: 100vh;
        width: 100%;

    }
    .loginform {
        background-color: #eeeded;
        padding: 50px 50px;
        display: flex ;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
        width: 95%;
        border-radius: 20px;
    }
    .quetionnnwala {
        display: none;
        position: fixed;
        bottom: 0;
        left: 5%;
        overflow: auto;
        max-height: 90vh !important;
        max-width: 107% !important;
        width: 90%;
        height: 50vh;
    }

    .quetionnnwala2 {
        display: flex;
       
    }

    

    .screen {
        /* background-color: #E63946; */
        min-width: 1px !important;
    }

    .vadio {
        /* background-color: #6E4E9F; */
        width: 90%;
        height: 80vh;
    }

    .preview {
        width: 80%;
        border-radius: 20px;
        height: fit-content;
        background-color: #fff;
        padding: 20px;
        display: flex;
        box-shadow: 0 0 10px 10px rgb(170, 176, 238);
        flex-direction: column;
    }

    .preview22 {
        display: flex;
        flex-direction: column;
    }


    .meetingdetails {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* background-color: yellow; */
    }

    .meetingjoin {
        /* background-color: violet; */
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


    .alertcontant {
        background-color: #fff;
        z-index: 10;
        width: 75%;
        min-height: 200px;
        height: fit-content;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -266px);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: 2em;
        box-shadow: 0 0 10px 1px #6E4E9F;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        text-align: center;
    }
}